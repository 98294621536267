import React from 'react';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import StyledModalInputContainer from '../../../../../../style/Inventory/Modal/EditModal/StyledModalInputContainer';
import {
  changeSelect,
  changeText,
  blurUpperCaseChange,
} from '../../../../../../util/Inventory/Common/Handler/controllerChangeHandler';
import { COMMON_STATE } from '../../../../../../constant/Common/Option/State';
import { REGEX_ONLY_NUMBER_ONLY_ALPHABET } from '../../../../../../constant/Common/Regex';

const TruckInfo = ({ data, isEditable }) => {
  return (
    <StyledModalInputContainer>
      <div>
        <CommonTextInput
          label="TRUCK #"
          inputName="truck_no"
          maxLength={10}
          defaultValue={data?.truck_no || null}
          onChangeHandler={changeText}
          onBlurHandler={blurUpperCaseChange}
          regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
          disabled={!isEditable}
          required
        />
      </div>
      <div>
        <CommonTextInput
          label="DOT #"
          inputName="dot_no"
          defaultValue={data?.dot_no || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={10}
        />
      </div>
      <div>
        <CommonTextInput
          label="PLATE #"
          inputName="plate_no"
          defaultValue={data?.plate_no || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={10}
        />
      </div>
      <div>
        <CommonSelect
          label="STATE"
          inputName="state"
          defaultValue={data?.state || ''}
          onChangeHandler={changeSelect}
          option={COMMON_STATE}
          disabled={!isEditable}
        />
      </div>
      <div>
        <CommonTextInput
          label="COMPANY"
          inputName="company"
          defaultValue={data?.company || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={20}
        />
      </div>
      <div>
        <CommonTextInput
          label="COLOR"
          inputName="color"
          defaultValue={data?.color || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={10}
        />
      </div>
      <div className="col-span-2">
        <CommonTextInput
          label="REMARKS"
          inputName="remarks"
          defaultValue={data?.remarks || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={50}
        />
      </div>
    </StyledModalInputContainer>
  );
};

export default TruckInfo;
