import MultiFloatingFilterDate from '../../../../component/Common/Datagrid/Component/MultiFilterRenderer/MultiFloatingFilterDate';
import MultiFilterHiddenInput from '../../../../component/Common/Datagrid/Component/MultiFilterRenderer/MultiFilterHiddenInput';
import generateDateTreePath from './generateDateTreePath';

const generateDateFilters = () => {
  return {
    suppressFloatingFilterButton: false,
    filter: 'agMultiColumnFilter',
    filterParams: {
      filters: [
        {
          filter: MultiFilterHiddenInput,
          floatingFilterComponent: MultiFloatingFilterDate,
        },
        {
          filter: 'agSetColumnFilter',
          filterParams: {
            treeList: true,
            excelMode: 'windows',
            closeOnApply: true,
            cellHeight: 24,
            treeListPathGetter: generateDateTreePath,
          },
        },
      ],
    },
  };
};

export default generateDateFilters;
