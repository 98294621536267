import { GRID_ROW_CHECKBOX_WIDTH } from '../../../../constant/Common/DataGrid/setting';

/**
 * @description
 * Function that generate select row checkbox.
 * @returns
 * Object that contains the select row checkbox.
 */
const generateSelectRowCheckbox = (pinnedCheckbox = false) => {
  return {
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: GRID_ROW_CHECKBOX_WIDTH,
    maxWidth: GRID_ROW_CHECKBOX_WIDTH,
    width: GRID_ROW_CHECKBOX_WIDTH,
    resizable: false,
    field: 'checkboxRowSelector',
    filter: false,
    floatingFilter: false,
    sortable: false,
    editable: false,
    suppressRowClickSelection: true,
    suppressMovable: true,
    onCellDoubleClicked: null,
    lockPosition: true,
    ...(pinnedCheckbox && { pinned: 'left' }),
  };
};

export default generateSelectRowCheckbox;
