/**
 * @description
 * Path of icon used in the datagrid component.
 */
const PATH_DATAGRID_ICON = '/asset/Icon/Datagrid';

export const PATH_DATAGRID_FOLD_ICON = {
  fold: `${PATH_DATAGRID_ICON}/gridFold.svg`,
  unfold: `${PATH_DATAGRID_ICON}/gridUnfold.svg`,
};

export const PATH_DATAGRID_GROUP_FOLD_ICON = {
  fold: `${PATH_DATAGRID_ICON}/groupFold.svg`,
  unfold: `${PATH_DATAGRID_ICON}/groupUnfold.svg`,
};

export const PATH_DATAGRID_GROUP_FOLD_WHITE_ICON = {
  fold: `${PATH_DATAGRID_ICON}/groupFoldWhite.svg`,
  unfold: `${PATH_DATAGRID_ICON}/groupUnfoldWhite.svg`,
};

export const PATH_DATAGRID_SORT_ICON = {
  none: `${PATH_DATAGRID_ICON}/sortDefault.svg`,
  asc: `${PATH_DATAGRID_ICON}/sortAsc.svg`,
  desc: `${PATH_DATAGRID_ICON}/sortDesc.svg`,
};

export const PATH_DATAGRID_TREE_ICON = {
  closed: `${PATH_DATAGRID_ICON}/treeClosed.svg`,
  open: `${PATH_DATAGRID_ICON}/treeOpen.svg`,
};

export const PATH_SET_FILTER_ICON = `${PATH_DATAGRID_ICON}/setFilter.svg`;
