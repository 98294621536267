import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { COMMON_STATE } from '../../../../constant/Common/Option/State';
import {
  STYLE_CONTENT_WRAPPER,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import {
  createMandatorySelectOption,
  createOptionalSelectOption,
} from '../../../../util/Common/Option/Generator/selectOptionCreator';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CommonCheckbox from '../../../Common/Controller/Checkbox/index';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';
import { REGEX_ONLY_NUMBER_ONLY_ALPHABET } from '../../../../constant/Common/Regex';

const Chassis = ({
  selectedDailyInOut,
  isAdd,
  isOut,
  inventoryChassisList,
  poolOption,
  inputCategory,
  setInputCategory,
  setIsBare,
  selectedYard,
  chassisTypeOption,
  isLoadingInventoryChassisList,
  existingData,
}) => {
  const { setValue, getValues } = useFormContext();
  const [selectedItem, setSelectedItem] = useState({});

  const resetFieldsData = () => {
    setValue('ch_no', '');
    setValue('ch_pool', '');
    setValue('ch_type', '');
    setValue('ch_plate_no', '');
    setValue('ch_state', '');
    setValue('ch_bare', false);
    setValue('ch_color', '');
    setValue('ch_yard_location', '');
    setValue('ch_remarks', '');
  };

  const resetContainerFieldsData = () => {
    setValue('cont_no', '');
    setValue('cont_size', '');
    setValue('cont_type', '');
    setValue('cont_ssl', '');
    setValue('cont_seal', '');
    setValue('cont_color', '');
    setValue('cont_yard_location', '');
    setValue('cont_remarks', '');
  };

  useEffect(() => {
    setSelectedItem({});
  }, [isOut]);

  useEffect(() => {
    if (inputCategory === 'trailer') {
      resetFieldsData();
    }
  }, [inputCategory]);

  useEffect(() => {
    setValue('bare', selectedItem?.bare);
  }, [selectedItem]);

  return (
    <div className="w-full h-[203px] bg-[#f9f9f9] mt-[10px] pt-[20px] pl-[36px] pr-[36px] flex flex-col gap-[10px]">
      {/* Header */}
      <div
        className={`w-full h-fit ${inputCategory === 'trailer' ? 'text-[#A7A7A7]' : 'text-[#264B9F]'} text-[14px] font-bold`}
      >
        Chassis
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            {isOut ? (
              <CommonAutoComplete
                label="CHASSIS #"
                inputName="ch_no"
                width="w-[140px]"
                disabled={
                  !isAdd ||
                  inputCategory === 'trailer' ||
                  (isOut && !selectedYard)
                }
                defaultValue={
                  !isAdd ? selectedDailyInOut?.ch_no : existingData?.ch_no
                }
                option={createMandatorySelectOption({
                  items: inventoryChassisList,
                  labelKey: 'ch_no',
                  valueKey: 'ch_no',
                })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  if (selectProps?.value !== selectedItem?.ch_no)
                    resetFieldsData();
                  onChange?.(selectProps?.value);
                  const selectedValue = inventoryChassisList?.find(
                    item => item?.ch_no === selectProps?.value,
                  );
                  setSelectedItem(selectedValue);
                  setValue('ch_pool', selectedValue?.pool);
                  setValue('ch_type', selectedValue?.type);
                  setValue('ch_state', selectedValue?.state);
                  if (selectProps?.value) {
                    setInputCategory('chassis');
                  } else if (getValues('cont_no')) {
                    setInputCategory('container');
                  } else {
                    setInputCategory('');
                  }
                }}
                isLoading={isLoadingInventoryChassisList}
              />
            ) : (
              <CommonTextInput
                label="CHASSIS #"
                inputName="ch_no"
                width="w-[140px]"
                disabled={!isAdd || isOut || inputCategory === 'trailer'}
                defaultValue={
                  !isAdd ? selectedDailyInOut?.ch_no : existingData?.ch_no
                }
                maxLength={12}
                onChangeHandler={({ e, onChange }) => {
                  const value = e?.target?.value || '';

                  if (
                    value === '' ||
                    REGEX_ONLY_NUMBER_ONLY_ALPHABET.test(value)
                  ) {
                    onChange(value?.toUpperCase());
                  }

                  if (value) setInputCategory('chassis');
                  else if (getValues('cont_no')) setInputCategory('container');
                  else setInputCategory('');
                }}
              />
            )}
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="POOL"
              inputName="ch_pool"
              width="w-[140px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.ch_pool : '') ||
                (isOut ? selectedItem?.pool : existingData?.ch_pool)
              }
              required={isAdd && getValues('ch_no')}
              option={poolOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="ch_type"
              width="w-[140px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd && selectedDailyInOut?.ch_type) ||
                (isOut ? selectedItem?.type : existingData?.ch_type)
              }
              option={chassisTypeOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="PLATE #"
              inputName="ch_plate_no"
              width="w-[140px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.ch_plate_no : '') ||
                (isOut ? selectedItem?.plate_no : existingData?.ch_plate_no)
              }
              maxLength={8}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="COLOR"
              inputName="ch_color"
              width="w-[110px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.ch_color : '') ||
                (isOut ? selectedItem?.color : existingData?.ch_color)
              }
              maxLength={10}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="LOCATION"
              inputName="ch_yard_location"
              width="w-[110px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.ch_yard_location : '') ||
                (isOut
                  ? selectedItem?.yard_location
                  : existingData?.ch_yard_location)
              }
              maxLength={5}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATE"
              inputName="ch_state"
              width="w-[140px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.ch_state : '') ||
                (isOut ? selectedItem?.state : existingData?.ch_state)
              }
              option={createOptionalSelectOption({
                items: COMMON_STATE,
                labelKey: 'label',
                valueKey: 'value',
              })}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonCheckbox
              label="BARE"
              inputName="ch_bare"
              width="w-[47px]"
              borderVisible
              customStyle={
                isAdd &&
                !isOut &&
                inputCategory !== 'trailer' && { bgColor: 'bg-white' }
              }
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              options={[
                {
                  inputName: 'bare',
                  defaultValue: !isAdd
                    ? selectedDailyInOut?.ch_bare
                    : existingData?.ch_bare || false,
                },
              ]}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.checked);
                if (e?.target?.checked) {
                  setIsBare(true);
                  resetContainerFieldsData();
                } else {
                  setIsBare(false);
                  if (getValues('ch_no')) setInputCategory('chassis');
                  else setInputCategory('');
                }
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="ch_remarks"
              width="w-[140px]"
              disabled={!isAdd || isOut || inputCategory === 'trailer'}
              defaultValue={
                (!isAdd ? selectedDailyInOut?.ch_remarks : '') ||
                (isOut ? selectedItem?.remarks : existingData?.ch_remarks)
              }
              maxLength={50}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chassis;
