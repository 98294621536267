import dayjs from 'dayjs';
import FloatingFilterInput from '../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import { DAILY_IN_OUT_TYPE } from '../../../constant/DailyInOut/elements';
import dateComparator from '../../Common/Datagrid/Comparator/dateComparator';
import generateDateFilters from '../../Common/Datagrid/Generator/generateDateFilters';

const createInOutColumnDefs = () => {
  return [
    {
      headerName: 'DATE',
      field: 'created_date',
      width: 62,
      editable: false,
      cellClass: 'date',
      valueGetter: params => {
        const dateValue = params.data?.created_date;
        return dateValue ? dayjs(dateValue).format('MM/DD/YY') : null;
      },
      comparator: dateComparator,
      ...generateDateFilters(),
    },
    {
      headerName: 'TIME',
      field: 'created_time',
      width: 48,
      editable: false,
      valueGetter: params => {
        const timeValue = params.data?.created_time;
        return timeValue
          ? dayjs(`${dayjs().format('YYYY-MM-DD')}T${timeValue}`).format(
              'HH:mm',
            )
          : null;
      },
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'time',
        maxLength: 5,
        placeholder: 'HH:mm',
      },
    },
    {
      headerName: 'WO #',
      field: 'wo_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'TYPE',
      field: 'in_out_type',
      width: 90,
      editable: false,
      valueGetter: params => {
        return DAILY_IN_OUT_TYPE[params?.data?.in_out_type];
      },
    },
    {
      headerName: 'YARD',
      field: 'yard',
      width: 70,
      editable: false,
    },
    {
      headerName: 'LOC',
      field: 'cont_yard_location',
      width: 72,
      editable: false,
    },
    {
      headerName: 'TRUCK #',
      field: 'truck_no',
      width: 100,
      editable: false,
    },
    {
      headerName: 'PLATE #',
      field: 'truck_plate_no',
      width: 85,
      editable: false,
    },
    {
      headerName: 'COMPANY',
      field: 'truck_company',
      width: 115,
      editable: false,
    },
    {
      headerName: 'EQUIPMENT',
      field: 'cont_no',
      width: 90,
      editable: false,
      valueGetter: params => {
        return params.data?.cont_no || params.data?.trailer_no;
      },
    },
    {
      headerName: 'SIZE',
      field: 'cont_size',
      width: 46,
      editable: false,
    },
    {
      headerName: 'SSL',
      field: 'cont_ssl',
      width: 50,
      editable: false,
    },
    {
      headerName: 'SEAL #',
      field: 'cont_seal',
      width: 128,
      editable: false,
    },
    {
      headerName: 'CHASSIS',
      field: 'ch_no',
      width: 86,
      editable: false,
    },
    {
      headerName: 'POOL',
      field: 'ch_pool',
      width: 52,
      editable: false,
    },
    {
      headerName: 'CH TYPE',
      field: 'ch_type',
      width: 77,
      editable: false,
    },
    {
      headerName: 'UPDATED BY',
      field: 'updated_by',
      width: 92,
      editable: false,
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      width: 172,
      editable: false,
    },
  ];
};

export default createInOutColumnDefs;
