import React, { useCallback } from 'react';
import { useGridFilter } from 'ag-grid-react';

const MultiFilterHiddenInput = ({ model, onModelChange, getValue }) => {
  /** doesFilterPass affects floating filter in multi filter
   * @see https://www.ag-grid.com/react-data-grid/filter-multi/#custom-filters
   */
  const doesFilterPass = useCallback(
    ({ node }) => {
      return getValue(node)?.includes(model);
    },
    [model],
  );

  const afterGuiAttached = () => {
    onModelChange(null);
  };

  useGridFilter({ doesFilterPass, afterGuiAttached });

  return <div />;
};

export default MultiFilterHiddenInput;
