import React from 'react';

const AccordionBox = ({ title, content, width }) => {
  return (
    <div className={`${width} h-fit`}>
      <div className="bg-[#CCD3E3] w-full px-[32px] flex justify-between items-center h-[40px]">
        <span className="text-[14px] text-[#222222] font-bold">
          {title || ''}
        </span>
      </div>
      <div className="w-full min-h-[356px] h-[356px] bg-[#FFFFFF]">
        <div className="h-full w-full p-[32px]">{content}</div>
      </div>
    </div>
  );
};

export default AccordionBox;
