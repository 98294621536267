import React from 'react';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonCheckbox from '../../../../../Common/Controller/Checkbox';
import StyledModalInputContainer from '../../../../../../style/Inventory/Modal/EditModal/StyledModalInputContainer';
import {
  changeSelect,
  changeText,
  changeCheckbox,
  blurUpperCaseChange,
} from '../../../../../../util/Inventory/Common/Handler/controllerChangeHandler';
import { COMMON_STATE } from '../../../../../../constant/Common/Option/State';
import { REGEX_ONLY_NUMBER_ONLY_ALPHABET } from '../../../../../../constant/Common/Regex';

const ChassisInfo = ({ data, selectOptions, isEditable }) => {
  return (
    <div>
      <StyledModalInputContainer>
        <div>
          <CommonTextInput
            label="CHASSIS #"
            inputName="ch_no"
            defaultValue={data?.ch_no || null}
            onChangeHandler={changeText}
            regex={REGEX_ONLY_NUMBER_ONLY_ALPHABET}
            maxLength={12}
            onBlurHandler={blurUpperCaseChange}
            disabled={!isEditable}
            required
          />
        </div>
        <div>
          <CommonSelect
            label="POOL"
            inputName="pool"
            defaultValue={data?.pool || ''}
            onChangeHandler={changeSelect}
            option={selectOptions.poolOption}
            disabled={!isEditable}
            required
          />
        </div>
        <div>
          <CommonSelect
            label="TYPE"
            inputName="type"
            defaultValue={data?.type || ''}
            onChangeHandler={changeSelect}
            option={selectOptions.chassisTypeOption}
          />
        </div>
        <div>
          <CommonTextInput
            label="PLATE #"
            inputName="plate_no"
            defaultValue={data?.plate_no || null}
            onChangeHandler={changeText}
            disabled={!isEditable}
            maxLength={8}
          />
        </div>
      </StyledModalInputContainer>
      <StyledModalInputContainer $columnSize="42px 116px 116px 190px">
        <div>
          <CommonCheckbox
            label="BARE"
            inputName="bare"
            borderVisible
            onChangeHandler={changeCheckbox}
            options={[
              {
                defaultValue: data?.bare || false,
                inputName: 'bare',
              },
            ]}
            disabled
          />
        </div>
        <div>
          <CommonTextInput
            label="COLOR"
            inputName="color"
            defaultValue={data?.color || null}
            onChangeHandler={changeText}
            disabled={!isEditable}
            maxLength={10}
          />
        </div>
        <div>
          <CommonSelect
            label="STATE"
            inputName="state"
            defaultValue={data?.state || ''}
            onChangeHandler={changeSelect}
            option={COMMON_STATE}
            disabled={!isEditable}
          />
        </div>
        <div>
          <CommonTextInput
            label="REMARKS"
            inputName="remarks"
            defaultValue={data?.remarks || null}
            onChangeHandler={changeText}
            disabled={!isEditable}
            maxLength={50}
          />
        </div>
      </StyledModalInputContainer>
    </div>
  );
};

export default ChassisInfo;
