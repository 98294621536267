import { AgGridReact } from 'ag-grid-react';
import styled from 'styled-components';
import {
  PATH_DATAGRID_SORT_ICON,
  PATH_DATAGRID_TREE_ICON,
  PATH_SET_FILTER_ICON,
} from '../../../../constant/Common/Path/Asset/Icon/Datagrid';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';

const StyledCommonDatagrid = styled(AgGridReact)`
  // Vertical line color between cells
  --ag-cell-horizontal-border: 1px solid #d9d9d9 !important;

  // Column header separator
  *:not(.ag-header-group-cell) {
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-width: 1px;
    --ag-header-column-separator-color: #d9d9d9;
  }
  *.ag-header-group-cell {
    --ag-header-column-separator-display: none;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-width: 0px;
    --ag-header-column-separator-color: #d9d9d9;
  }

  // Column resize bar
  .ag-header-cell-resize::after {
    background-color: unset !important;
    top: 0 !important;
    height: 100%;
  }

  .ag-row-odd {
    background-color: #fff;
  }
  .ag-row {
    border-bottom: 2px solid #f2f4f8;
  }
  .ag-root-wrapper {
    border: none;
  }
  .ag-header {
    background-color: #fff;
    border-bottom: 2px solid #f2f4f8;
  }
  .ag-header-cell-text {
    font-size: 13px;
  }
  .ag-floating-filter {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-left: 1px !important;
    padding-right: 2px !important;
  }
  .ag-row-pinned > .ag-cell {
    padding-left: 0px !important;
    padding-right: 1px !important;
  }
  .ag-cell-value {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .ag-cell {
    display: flex;
    align-items: center;
  }
  .ag-cell-inline-editing {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ag-row {
    &:hover {
      background-color: #f2f4f8 !important;
    }
  }
  .ag-row-selected {
    outline: none !important;
  }
  .ag-icon {
    color: unset !important;
  }
  .ag-icon-none {
    background: transparent center/contain no-repeat !important;
    background-image: url(${PATH_DATAGRID_SORT_ICON?.none}) !important;
    color: transparent !important;
  }
  .ag-icon-asc {
    background: transparent center/contain no-repeat !important;
    background-image: url(${PATH_DATAGRID_SORT_ICON?.asc}) !important;
    color: transparent !important;
  }
  .ag-icon-desc {
    background: transparent center/contain no-repeat !important;
    background-image: url(${PATH_DATAGRID_SORT_ICON?.desc}) !important;
    color: transparent !important;
  }
  input {
    box-shadow: none !important;
    border-radius: 5px !important;
    border: 1px solid #d9d9d9 !important;
    height: 24px !important;
    padding-left: 12px !important;
  }
  .ag-icon-none {
    position: absolute;
    top: 9px;
    right: -1px;
    height: 12px;
  }
  .ag-icon-asc {
    position: absolute;
    top: 9px;
    right: -1px;
    height: 12px;
  }
  .ag-icon-desc {
    position: absolute;
    top: 9px;
    right: -1px;
    height: 12px;
  }
  .ag-checkbox-input-wrapper {
    font-family: agGridAlpine !important;
  }
  .ag-checkbox-input {
    font-family: agGridAlpine !important;
    margin-top: -10px !important;
  }
  .ag-header-row:not(:first-child)
    .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
  .ag-header-row:not(:first-child)
    .ag-header-group-cell.ag-header-group-cell-with-group {
    border-top: none;
  }
  .ag-row-hover::before {
    transition-duration: 300ms;
    background-color: #f2f4f8 !important;
    background-image: none !important;
  }
  .ag-icon-filter {
    display: none !important;
  }
  .ag-icon-filter {
    overflow: visible;
  }
  ${props => {
    const filteredIcon = props?.icon?.filter(icon => icon);
    const filteredColor = props?.color?.filter(color => color);
    const filteredBg = props?.background?.filter(bg => bg);

    let headerExpandIcon = ``;
    const isColIDStartWithZero = !props?.foldable;
    const groupIdxStartNumber = isColIDStartWithZero ? 0 : 1;
    for (let groupIdx = groupIdxStartNumber; groupIdx < 100; groupIdx++) {
      headerExpandIcon += `div[col-id='${groupIdx}_0'].ag-header-group-cell-with-group > .ag-header-group-cell-label > .ag-header-expand-icon-expanded {
        background: transparent center/contain no-repeat !important;
        background-image: url(${filteredIcon?.[groupIdx - groupIdxStartNumber]?.fold}) !important;
        color: transparent !important;
        margin-left: 12px !important;
      }

      div[col-id='${groupIdx}_0'].ag-header-group-cell-with-group > .ag-header-group-cell-label >.ag-header-expand-icon-collapsed {
        background: transparent center/contain no-repeat !important;
        background-image: url(${filteredIcon?.[groupIdx - groupIdxStartNumber]?.unfold}) !important;
        color: transparent !important;
        margin-left: 12px !important;
      }

      div[col-id='${groupIdx}_0'].ag-header-group-cell-with-group {
          background-color: ${
            filteredBg?.[groupIdx - groupIdxStartNumber] || 'none'
          } !important;
          color: ${filteredColor?.[groupIdx - groupIdxStartNumber] || 'none'} !important;
        }
      
      `;
    }
    return headerExpandIcon;
  }}

  .ag-header-group-cell-no-group {
    background-color: transparent !important;
  }
  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: none;
  }
  .ag-floating-top-container {
    height: 40px;
  }
  .ag-row-pinned {
    background-color: #f8f2f2;
  }
  .ag-header-row-column-group {
    height: 30px !important;
    background-color: #f2f4f8;
  }

  /** Make a resizable bar inside a group invisible */
  .ag-header-group-cell > .ag-header-cell-resize {
    display: none;
  }

  /** Make Fold button invisible */
  .ag-group-contracted {
    display: none !important;
  }
  .ag-group-expanded {
    display: none !important;
  }

  /** Set group cell width and height */
  .ag-cell-expandable {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ag-group-value {
    width: 100%;
    height: 100%;
  }

  /** Filter layout */
  .ag-input-field-input {
    padding-left: 2px !important;
  }

  /** icon for Set Filter open */
  .ag-floating-filter-button {
    margin-left: 0.5px;
    background: transparent contain no-repeat !important;
    background-image: url(${PATH_SET_FILTER_ICON}) !important;
  }
  .ag-floating-filter-button-button {
    box-shadow: none !important;
  }
  /** check box style for Set Filter */
  .ag-theme-alpine {
    --ag-checkbox-checked-color: #264b9f;
    --ag-checkbox-unchecked-color: #264b9f;
    --ag-checkbox-indeterminate-color: grey;
    --ag-selected-tab-underline-color: transparent;
  }
  /** tree icons for Set Filter */
  .ag-icon-tree-closed {
    background: transparent center no-repeat !important;
    background-image: url(${PATH_DATAGRID_TREE_ICON?.closed}) !important;
    color: transparent !important;
  }
  .ag-icon-tree-indeterminate {
    background: transparent center no-repeat !important;
    background-image: url(${PATH_DATAGRID_TREE_ICON?.closed}) !important;
    color: transparent !important;
  }
  .ag-icon-tree-open {
    background: transparent center no-repeat !important;
    background-image: url(${PATH_DATAGRID_TREE_ICON?.open}) !important;
    color: transparent !important;
  }

  /** Filter popup style */
  .ag-filter-separator {
    border: none;
  }
  .ag-popup {
    z-index: 99;
  }
  .ag-menu-header {
    border: none;
    background-color: white;
    z-index: 1000;
  }
  .ag-mini-filter {
    margin: 0;
    margin-bottom: 4px;
  }
  .ag-menu {
    min-width: 250px;
    background-color: white;
    border: none;
  }
  .ag-set-filter {
    margin: 16px 24px 0px 24px;
  }
  .ag-filter-virtual-list-container {
    margin: 8px;
  }
  .ag-set-filter-list {
    background-color: #f2f4f8;
    min-height: 200px;
  }
  .ag-set-filter-item {
    font-weight: 500;
  }
  .ag-filter-apply-panel {
    justify-content: center;
    border: none;
    padding: 20px;
  }
  .ag-filter-apply-panel-button {
    display: flex;
    align-items: center;
    gap: 3px;
    width: fit-content;
    height: 18px;
    font-size: 10px;
    font-weight: 500;
    margin: 0 5px;
    padding: 0 8px;
    border: solid 1px;
    border-radius: 3px;
    cursor: pointer;
    &:focus {
      box-shadow: none;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &[ref='applyFilterButton'] {
      border-color: #556da1;
      color: #556da1;
      &:hover {
        color: white;
        background-color: #556da1;
        border-color: #556da1;
      }
      &:active {
        color: white;
        background-color: #556da1;
        border-color: #556da1;
      }
      &:disabled {
        background-color: #a6a6a6;
        border: 1px solid #a6a6a6;
        color: white;
        cursor: not-allowed;
      }
      &:hover:disabled {
        background-color: #a6a6a6;
        border: 1px solid #a6a6a6;
        color: white;
        cursor: not-allowed;
      }
    }
    &[ref='cancelFilterButton'] {
      border-color: #b74747;
      color: #b74747;
      &:hover {
        color: white;
        background-color: #b74747;
        border-color: #b74747;
      }
      &:active {
        color: white;
        background-color: #b74747;
        border-color: #b74747;
      }
    }
  }
  .ag-filter-apply-panel-button[ref='applyFilterButton']::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.circleCheckBlue?.default});
  }
  .ag-filter-apply-panel-button[ref='applyFilterButton']:hover::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.circleCheckBlue?.hover});
  }
  .ag-filter-apply-panel-button[ref='applyFilterButton']:active::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.circleCheckBlue?.hover});
  }
  .ag-filter-apply-panel-button[ref='applyFilterButton']:disabled::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.circleCheckBlue?.hover});
  }
  .ag-filter-apply-panel-button[ref='cancelFilterButton']::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.cancelRed?.default});
  }
  .ag-filter-apply-panel-button[ref='cancelFilterButton']:hover::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.circleCheckBlue?.hover});
  }
  .ag-filter-apply-panel-button[ref='applyFilterButton']:active::before {
    background-image: url(${PATH_COMMON_BUTTON_ICON?.circleCheckBlue?.hover});
  }
`;

export default StyledCommonDatagrid;
