import React, { useRef } from 'react';
import { TextField } from '@mui/material';
import StyledEditCellRenderer from '../../../../../../style/Common/Datagrid/EditCellRenderer/StyledEditCellRenderer';
import convertFilterValue from '../../../../../../util/Common/Datagrid/Handler/convertFilterValue';
import { FLOATING_FILTER_INPUT_SX } from '../../../../../../style/Common/Datagrid/EditCellRenderer/StyledEditCellRenderer/style';

const MultiFloatingFilterDatetime = ({ model, onModelChange }) => {
  const compRef = useRef(null);

  return (
    <StyledEditCellRenderer>
      <TextField
        variant="outlined"
        ref={compRef}
        value={model || ''}
        onChange={({ target: { value } }) => {
          onModelChange(convertFilterValue({ value, inputType: 'datetime' }));
        }}
        sx={FLOATING_FILTER_INPUT_SX}
        placeholder="MM/DD/YY HH:mm"
        InputProps={{
          inputProps: {
            maxLength: 14,
            autoComplete: 'off',
          },
        }}
      />
    </StyledEditCellRenderer>
  );
};

export default MultiFloatingFilterDatetime;
