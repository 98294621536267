import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PATH_HEADER_NAVIGATOR_ARROW_ICON,
  PATH_HEADER_NAVIGATOR_ICON,
} from '../../../../../constant/Common/Path/Asset/Icon/Button';
import HEADER_NAVIGATOR_ROUTER from '../../../../../constant/Common/Router/App/Common/Header/Navigator';
import useControlCommonItemOpen from '../../../../../hook/Common/Open/useControlCommonFilterOpen';

const Navigator = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { buttonRef, modalRef } = useControlCommonItemOpen({
    open: isOpen,
    setOpen: setIsOpen,
  });

  return (
    <div className="relative">
      {/* Navigator Icon */}
      <div
        ref={buttonRef}
        className="w-[47px] h-[33px] flex justify-center items-center cursor-pointer
                    hover:bg-[#556DA1] rounded-[5px] duration-300"
        onClick={() => setIsOpen(prev => !isOpen)}
      >
        <img src={PATH_HEADER_NAVIGATOR_ICON} alt="Header Navigator Icon" />
      </div>

      {/* Sub Menu */}
      {isOpen && (
        <div
          ref={modalRef}
          className="absolute top-[50px] left-0 w-[112px] h-fit"
        >
          {/* First element */}
          <div className="w-full h-[37px] bg-[#001E5F] rounded-[10px_10px_0px_0px] flex items-center pl-[10px] gap-[10px]">
            <img
              src={PATH_HEADER_NAVIGATOR_ICON}
              alt="Header navigator router icon"
              className="w-[16px] h-[16px]"
            />
            <p className="text-[#fff] text-[16px] font-bold">Others</p>
          </div>

          {/* Router element */}
          {HEADER_NAVIGATOR_ROUTER.map(({ seq, label, to }) => {
            // Check if the current element is the last element
            const isLast = seq === HEADER_NAVIGATOR_ROUTER.length - 1;
            return (
              <Link key={seq} to={to} target="_blank">
                <div
                  className={`w-full h-[35px] bg-[#fff] flex items-center justify-between px-[15px] cursor-pointer
                            ${isLast && 'rounded-[0px_0px_10px_10px]'}
                `}
                >
                  <p className="text-[#001E5F] text-[12px] font-bold">
                    {label}
                  </p>
                  <img
                    src={PATH_HEADER_NAVIGATOR_ARROW_ICON}
                    alt="Header Navigator Arrow Icon"
                  />
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Navigator;
