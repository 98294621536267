import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CommonDataGrid from '../../../component/Common/Datagrid/index';
import CommonLoading from '../../../component/Common/Loading/index';
import CommonAlert from '../../../component/Common/Modal/Alert';
import TotalBottomBar from '../../../component/Common/TotalBottomBar';
import AddButton from '../../../component/DailyInOut/AddButton';
import AddEditModal from '../../../component/DailyInOut/AddEditModal';
import AdvancedSearchModal from '../../../component/DailyInOut/AdvancedSearchModal';
import ExcelButton from '../../../component/DailyInOut/ExcelButton';
import FlipButton from '../../../component/DailyInOut/FlipButton';
import FlipModal from '../../../component/DailyInOut/FlipModal';
import SearchButton from '../../../component/DailyInOut/SearchButton';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import { PERM_DAILY_IN_OUT_DAILY_EDIT } from '../../../constant/Common/Permission';
import useControlTotalInfo from '../../../hook/Common/TotalBottomBar/useControlTotalInfo';
import useGetDailyInOutList from '../../../hook/DailyInOut/Get/useGetDailyInOutList';
import useGetInventoryChassisList from '../../../hook/DailyInOut/Get/useGetInventoryChassisList';
import useGetInventoryContainerList from '../../../hook/DailyInOut/Get/useGetInventoryContainerList';
import useGetInventoryTrailerList from '../../../hook/DailyInOut/Get/useGetInventoryTrailerList';
import useGetInventoryTruckList from '../../../hook/DailyInOut/Get/useGetInventoryTruckList';
import useGetSelectedDailyInOut from '../../../hook/DailyInOut/Get/useGetSelectedDailyInOut';
import usePatchDailyInOutMutation from '../../../hook/DailyInOut/Patch/usePatchDailyInOutMutation';
import usePostDailyInOutMutation from '../../../hook/DailyInOut/Post/usePostDailyInOutMutation';
import { createOptionalSelectOption } from '../../../util/Common/Option/Generator/selectOptionCreator';
import checkPermission from '../../../util/Common/Router/Handler/checkPermission';
import createInOutColumnDefs from '../../../util/DailyInOut/Generator/createDailyInOutColumnDefs';
import RefreshButton from '../../../component/Common/RefreshButton';
import dailyInOutKeys from '../../../hook/DailyInOut/keys';

const DailyInOut = () => {
  const gridRef = useRef(null);
  const addEditModalController = useForm();
  const currDiv = useSelector(state => state?.common?.currDiv);
  const yardList = useSelector(state => state?.common?.ymsYard);
  const currDivYardList = yardList.filter(yard => yard?.div === currDiv);
  const yardOption = createOptionalSelectOption({
    items: currDivYardList,
    labelKey: 'name',
    valueKey: 'name',
  });
  const divList = createOptionalSelectOption({
    items: useSelector(state => state?.common?.divisionList),
    labelKey: 'div',
    valueKey: 'div',
  });
  const driverList = useSelector(state => state?.common?.driverList);
  const driverOption = driverList?.map(driver => ({
    value: driver.id,
    label: `${driver.id} / ${driver.name}`,
  }));
  const typeList = useSelector(state => state?.common?.typeList);
  const typeOption = createOptionalSelectOption({
    items: typeList,
    labelKey: 'type',
    valueKey: 'type',
  });
  const poolList = useSelector(state => state?.common?.poolList);
  const poolOption = createOptionalSelectOption({
    items: poolList,
    labelKey: 'pool',
    valueKey: 'pool',
  });
  const sizeList = useSelector(state => state?.common?.sizeList);
  const sizeOption = createOptionalSelectOption({
    items: sizeList,
    labelKey: 'size',
    valueKey: 'size',
  });
  const sslList = useSelector(state => state?.common?.sslList);
  const sslOption = createOptionalSelectOption({
    items: sslList,
    labelKey: 'ssl',
    valueKey: 'ssl',
  });
  const chassisTypeList = useSelector(state => state?.common?.chassisTypeList);
  const chassisTypeOption = createOptionalSelectOption({
    items: chassisTypeList,
    labelKey: 'type',
    valueKey: 'type',
  });

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isFlipModalOpen, setIsFlipModalOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState('');
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
  );
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [yard, setYard] = useState('');
  const [equipmentType, setEquipmentType] = useState('');
  const [equipment, setEquipment] = useState('');
  const [selectedSeq, setSelectedSeq] = useState(0);
  const [selectedYard, setSelectedYard] = useState(null);
  const isEditable = checkPermission({
    permission: PERM_DAILY_IN_OUT_DAILY_EDIT,
  });
  const [columnDefs, setColumnDefs] = useState(createInOutColumnDefs());

  const {
    isLoadingDailyInOutList,
    dailyInOutList,
    isRefetchingDailyInOutList,
    isErrorDailyInOutList,
  } = useGetDailyInOutList({
    div: currDiv,
    fromDate,
    toDate,
    yard,
    equipmentType,
    equipment,
  });

  const {
    isLoadingSelectedDailyInOut,
    selectedDailyInOut,
    isErrorSelectedDailyInOut,
  } = useGetSelectedDailyInOut({ seq: selectedSeq });

  const {
    isLoadingInventoryTruckList,
    inventoryTruckList,
    isErrorInventoryTruckList,
  } = useGetInventoryTruckList({ div: currDiv, yard: selectedYard });

  const {
    isLoadingInventoryContainerList,
    inventoryContainerList,
    isErrorInventoryContainerList,
  } = useGetInventoryContainerList({ div: currDiv, yard: selectedYard });

  const {
    isLoadingInventoryTrailerList,
    inventoryTrailerList,
    isErrorInventoryTrailerList,
  } = useGetInventoryTrailerList({ div: currDiv, yard: selectedYard });

  const {
    isLoadingInventoryChassisList,
    inventoryChassisList,
    isErrorInventoryChassisList,
  } = useGetInventoryChassisList({ div: currDiv, yard: selectedYard });

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: dailyInOutList,
  });

  const { mutate: postDailyInOutMutation } = usePostDailyInOutMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    div: currDiv,
    fromDate,
    toDate,
    yard,
    setIsAddEditModalOpen,
    addEditModalController,
    equipmentType,
    equipment,
  });

  const { mutate: patchDailyInOutMutation } = usePatchDailyInOutMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    setIsAddEditModalOpen,
    addEditModalController,
    selectedSeq,
    gridRef,
  });

  useEffect(() => {
    const errorStates = [
      { isError: isErrorDailyInOutList, name: 'Daily In/Out List' },
      { isError: isErrorSelectedDailyInOut, name: 'Selected Daily In/Out' },
      { isError: isErrorInventoryTruckList, name: 'Inventory Truck List' },
      {
        isError: isErrorInventoryContainerList,
        name: 'Inventory Container List',
      },
      { isError: isErrorInventoryTrailerList, name: 'Inventory Trailer List' },
      { isError: isErrorInventoryChassisList, name: 'Inventory Chassis List' },
    ];

    const foundError = errorStates.find(state => state.isError);

    if (foundError) {
      setMessageStatus(ALERT_MODAL_STATUS.ERROR);
      setIsMessageOpen(true);
      setMessage(`Error occurred while fetching data for ${foundError.name}`);
    }
  }, [
    isErrorDailyInOutList,
    isErrorSelectedDailyInOut,
    isErrorInventoryTruckList,
    isErrorInventoryContainerList,
    isErrorInventoryTrailerList,
    isErrorInventoryChassisList,
  ]);

  return (
    <>
      <AdvancedSearchModal
        isSearchModalOpen={isSearchModalOpen}
        setIsSearchModalOpen={setIsSearchModalOpen}
        yardOption={yardOption}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        yard={yard}
        setYard={setYard}
        setIsMessageOpen={setIsMessageOpen}
        setMessage={setMessage}
        setMessageStatus={setMessageStatus}
        equipmentType={equipmentType}
        setEquipmentType={setEquipmentType}
        equipment={equipment}
        setEquipment={setEquipment}
      />
      {(isAdd || (!isAdd && selectedDailyInOut)) && (
        <AddEditModal
          isAddEditModalOpen={isAddEditModalOpen}
          setIsAddEditModalOpen={setIsAddEditModalOpen}
          isAdd={isAdd}
          setIsAdd={setIsAdd}
          selectedDailyInOut={selectedDailyInOut}
          divList={divList}
          yardOption={yardOption}
          driverOption={driverOption}
          typeOption={typeOption}
          poolOption={poolOption}
          sizeOption={sizeOption}
          sslOption={sslOption}
          inventoryTruckList={inventoryTruckList}
          inventoryContainerList={inventoryContainerList}
          inventoryTrailerList={inventoryTrailerList}
          inventoryChassisList={inventoryChassisList}
          postDailyInOutMutation={postDailyInOutMutation}
          patchDailyInOutMutation={patchDailyInOutMutation}
          div={currDiv}
          selectedYard={selectedYard}
          setSelectedYard={setSelectedYard}
          addEditModalController={addEditModalController}
          setMessage={setMessage}
          setMessageStatus={setMessageStatus}
          setIsMessageOpen={setIsMessageOpen}
          chassisTypeOption={chassisTypeOption}
          isLoadingInventoryTruckList={isLoadingInventoryTruckList}
          isLoadingInventoryContainerList={isLoadingInventoryContainerList}
          isLoadingInventoryTrailerList={isLoadingInventoryTrailerList}
          isLoadingInventoryChassisList={isLoadingInventoryChassisList}
        />
      )}
      <FlipModal
        isFlipModalOpen={isFlipModalOpen}
        setIsFlipModalOpen={setIsFlipModalOpen}
        yardOption={yardOption}
        setIsMessageOpen={setIsMessageOpen}
        setMessage={setMessage}
        setMessageStatus={setMessageStatus}
        fromDate={fromDate}
        toDate={toDate}
        yard={yard}
        equipmentType={equipmentType}
        equipment={equipment}
      />
      <div className="pl-[29px] pr-[40px] flex flex-col flex-1 pb-8">
        <div className="mt-[20px] mb-[20px] flex justify-end gap-[5px]">
          <RefreshButton
            queryKeyList={[
              dailyInOutKeys?.list({
                div: currDiv,
                fromDate,
                toDate,
                yard,
                equipmentType,
                equipment,
              }),
            ]}
          />
          <SearchButton setIsSearchModalOpen={setIsSearchModalOpen} />
          <div className="flex items-center gap-x-[10px]">
            {isEditable && (
              <>
                <AddButton
                  setIsAddEditModalOpen={setIsAddEditModalOpen}
                  setIsAdd={setIsAdd}
                />
                <FlipButton setIsFlipModalOpen={setIsFlipModalOpen} />
              </>
            )}
            <ExcelButton gridRef={gridRef} />
          </div>
        </div>
        <div className="h-full">
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={dailyInOutList}
            onCellClicked={cell => {
              if (cell?.gridParam?.column?.colId !== 'remarks') {
                setSelectedSeq(cell?.gridParam?.data?.seq);
                setIsAddEditModalOpen(true);
                setIsAdd(false);
              }
            }}
            onFilterChange={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
        </div>
        <TotalBottomBar totalRows={totalRows} />
      </div>
      <CommonLoading
        isOpen={isLoadingDailyInOutList || isLoadingSelectedDailyInOut}
      />
      {messageStatus !== ALERT_MODAL_STATUS?.SUCCESS && (
        <CommonAlert
          isOpen={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          status={messageStatus}
          content={message}
          onOKButtonClick={() => setIsMessageOpen(false)}
          onCloseButtonClick={() => setIsMessageOpen(false)}
        />
      )}
    </>
  );
};

export default DailyInOut;
