import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import YMSYardContainer from '../../../component/Admin/DivConf/YMSYardContainer/index';
import CommonLoading from '../../../component/Common/Loading/index';
import CommonAlert from '../../../component/Common/Modal/Alert';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import useDeleteYMSYardMutation from '../../../hook/Admin/DivConf/Delete/useDeleteYMSYardMutation';
import useGetYMSYard from '../../../hook/Admin/DivConf/Get/useGetYMSYard';
import usePatchYMSYardActivateMutation from '../../../hook/Admin/DivConf/Patch/usePatchYMSYardActivateMutation';
import usePostYMSYardMutation from '../../../hook/Admin/DivConf/Post/ustPostYMSYardMutation';
import AccordionBox from '../../../component/Admin/DivConf/AccordionBox';

const DivConf = () => {
  const divConfController = useForm();
  const nowDiv = useSelector(state => state.common.currDiv);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(0);

  const {
    isLoadingYMSYard,
    data: ymsYard,
    isRefetchingYMSYard,
    isErrorYMSYard,
  } = useGetYMSYard({ div: nowDiv });

  const { mutate: postYMSYardMutation } = usePostYMSYardMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    div: nowDiv,
    divConfController,
  });

  const { mutate: patchYMSYardActivateMutation } =
    usePatchYMSYardActivateMutation({
      setIsMessageOpen,
      setMessage,
      setMessageStatus,
      div: nowDiv,
    });

  const { mutate: deleteYMSYardMutation } = useDeleteYMSYardMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    div: nowDiv,
  });

  useEffect(() => {
    if (isErrorYMSYard) {
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setIsMessageOpen(true);
      setMessage('Error occurred while fetching data');
    }
  }, [isErrorYMSYard]);

  return (
    <>
      <CommonLoading open={isLoadingYMSYard || isRefetchingYMSYard} />
      {messageStatus !== ALERT_MODAL_STATUS.SUCCESS && (
        <CommonAlert
          isOpen={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          status={messageStatus}
          content={message}
          onOKButtonClick={() => setIsMessageOpen(false)}
          onCloseButtonClick={() => setIsMessageOpen(false)}
        />
      )}
      <div data-testid="yms-yard" className="p-[29px]">
        {ymsYard && (
          <FormProvider {...divConfController}>
            <form>
              <AccordionBox
                width="w-[456px]"
                title="YMS YARD"
                content={
                  <div className="w-full h-full">
                    <YMSYardContainer
                      ymsYard={ymsYard}
                      div={nowDiv}
                      postYMSYardMutation={postYMSYardMutation}
                      patchYMSYardActivateMutation={
                        patchYMSYardActivateMutation
                      }
                      deleteYMSYardMutation={deleteYMSYardMutation}
                    />
                  </div>
                }
              />
            </form>
          </FormProvider>
        )}
      </div>
    </>
  );
};

export default DivConf;
