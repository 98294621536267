import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const clickGridCell = ({
  setSpecificSeq,
  setModalOpen,
  setEditMode,
  ...props
}) => {
  setEditMode(EDIT_MODE?.edit);
  setSpecificSeq(props?.gridParam?.data?.seq);
  setModalOpen(true);
};

export default clickGridCell;
