import React, { useRef } from 'react';
import CommonDataGrid from '../../../Common/Datagrid';
import StyledSafetyDatagridWrapper from '../../../../style/Safety/Common/SafetyDatagrid/StyledSafetyDatagridWrapper';
import TotalBottomBar from '../../../Common/TotalBottomBar';
import useControlTotalInfo from '../../../../hook/Common/TotalBottomBar/useControlTotalInfo';
import clickGridCell from '../../../../util/Safety/Common/Handler/clickGridCell';

const SafetyDatagrid = ({ ...props }) => {
  const {
    gridRef,
    columnDefs,
    data,
    setSpecificSeq,
    setModalOpen,
    setEditMode,
  } = props;

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: data,
  });

  return (
    <StyledSafetyDatagridWrapper>
      <CommonDataGrid
        gridRef={gridRef || null}
        columnDefs={columnDefs || []}
        data={data}
        onCellClicked={cell =>
          clickGridCell?.({
            setSpecificSeq,
            setModalOpen,
            setEditMode,
            ...cell,
          })
        }
        onFilterChange={() => {
          setChangeFlag(prev => !prev);
        }}
        onSelectionChanged={() => {
          setChangeFlag(prev => !prev);
        }}
      />
      <TotalBottomBar totalRows={totalRows} />
    </StyledSafetyDatagridWrapper>
  );
};

export default SafetyDatagrid;
