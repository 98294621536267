import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';
import {
  generateFormattedDay,
  generateFormattedTime,
} from '../../Common/Generator/formattedDayGenerator';

const createTruckColumnDefs = () => {
  return [
    {
      headerName: 'DATE',
      field: 'created_date',
      width: 62,
      editable: false,
      cellClass: 'date',
      valueGetter: params =>
        generateFormattedDay(
          params?.data?.created_date
            ? params?.data?.created_date.split(' ')[0]
            : '',
          'MM/DD/YY',
        ),
      comparator: dateComparator,
      ...generateDateFilters(),
    },
    {
      headerName: 'TIME',
      field: 'created_time',
      width: 48,
      editable: false,
      valueGetter: params =>
        generateFormattedTime(
          params?.data?.created_date
            ? params?.data?.created_date.split(' ')[1]
            : '',
        ),
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'time',
        maxLength: 5,
        placeholder: 'HH:mm',
      },
    },
    {
      headerName: 'YARD',
      field: 'yard',
      width: 70,
      editable: false,
      floatingFilter: false,
    },
    {
      headerName: 'LOC',
      field: 'yard_location',
      width: 72,
      editable: false,
    },
    {
      headerName: 'TRUCK #',
      field: 'truck_no',
      width: 69,
      editable: false,
    },
    {
      headerName: 'COMPANY',
      field: 'company',
      width: 150,
      editable: false,
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
};

export default createTruckColumnDefs;
