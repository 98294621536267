import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import {
  ACCIDENT_STATUS_CODE,
  OP_CODE,
  RESPONSIBILITY_CODE,
} from '../../../../constant/Safety/Accident/standardCodes';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';
import { createOptionalSelectOption } from '../../../Common/Option/Generator/selectOptionCreator';
import { STYLE_COLUMN_ALIGNMENT } from '../../../../style/Safety/Common/style';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import {
  generateDateValue,
  generateOPValue,
  generateResponsibility,
  generateStatusValue,
} from './dataGridDataSetter';
import generateDateFilters from '../../../Common/Datagrid/Generator/generateDateFilters';

const createAccidentColumnDefs = ({ filterDivisionList }) => {
  return [
    {
      headerName: 'STATUS',
      width: 75,
      field: 'status',
      editable: false,
      valueGetter: gridParam => {
        const value = gridParam?.data?.status;
        return generateStatusValue(value);
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: createOptionalSelectOption({
          items: Object?.keys?.(ACCIDENT_STATUS_CODE),
          isGridFilter: true,
        }),
      },
    },
    {
      headerName: 'DIV',
      width: 38,
      field: 'div',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: filterDivisionList,
      },
    },
    { headerName: 'NAME', width: 72, field: 'driver', editable: false },
    {
      headerName: 'OP',
      width: 50,
      field: 'op',
      editable: false,
      valueGetter: gridParam => {
        const value = gridParam?.data?.op;
        return generateOPValue(value);
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: createOptionalSelectOption({
          items: Object?.keys?.(OP_CODE),
          isGridFilter: true,
        }),
      },
    },
    {
      headerName: 'RESP',
      width: 65,
      field: 'responsibility',
      editable: false,
      valueGetter: gridParam => {
        const value = gridParam?.data?.responsibility;
        return generateResponsibility(value);
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: createOptionalSelectOption({
          items: Object?.keys?.(RESPONSIBILITY_CODE),
          isGridFilter: true,
        }),
      },
    },
    {
      headerName: 'DATE',
      field: 'accident_date',
      width: 62,
      editable: false,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = gridParam?.data?.accident_date;
        return generateDateValue(value);
      },
      comparator: dateComparator,
      ...generateDateFilters(),
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            ...STYLE_COLUMN_ALIGNMENT.date,
          },
        }),
    },
    {
      headerName: 'DETAIL',
      width: 160,
      field: 'detail',
      editable: false,
    },
    {
      headerName: 'AMT TO PAY',
      width: 100,
      field: 'amt_to_pay',
      editable: false,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            ...STYLE_COLUMN_ALIGNMENT.number,
          },
          adornment: {
            content: '$',
            position: 'start',
          },
        }),
    },
    {
      headerName: 'WHO WILL PAY',
      width: 130,
      field: 'who_will_pay',
      editable: false,
    },
    {
      headerName: 'PAID AMT',
      width: 76,
      field: 'paid_amt',
      editable: false,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            ...STYLE_COLUMN_ALIGNMENT.number,
          },
          adornment: {
            content: '$',
            position: 'start',
          },
        }),
    },
    {
      headerName: 'PAID DATE',
      width: 80,
      field: 'paid_date',
      editable: false,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = gridParam?.data?.paid_date;
        return generateDateValue(value);
      },
      comparator: dateComparator,
      ...generateDateFilters(),
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            ...STYLE_COLUMN_ALIGNMENT.date,
          },
        }),
    },
    {
      headerName: 'BALANCE DUE',
      width: 100,
      field: 'balance_due',
      editable: false,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            ...STYLE_COLUMN_ALIGNMENT.number,
          },
          adornment: {
            content: '$',
            position: 'start',
          },
        }),
    },
    {
      headerName: 'CLAIM #',
      width: 130,
      field: 'claim_no',
      editable: false,
    },
    {
      headerName: 'CLAIM DATE',
      width: 93,
      field: 'claim_date',
      editable: false,
      cellClass: 'date',
      valueGetter: gridParam => {
        const value = gridParam?.data?.claim_date;
        return generateDateValue(value);
      },
      comparator: dateComparator,
      ...generateDateFilters(),
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            ...STYLE_COLUMN_ALIGNMENT.date,
          },
        }),
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
};

export default createAccidentColumnDefs;
