import React from 'react';
import {
  PATH_ARROW_DOWN_BLUE_ICON,
  PATH_DELETE_GREY_ONLY_ICON,
  PATH_DELETE_RED_ONLY_ICON,
} from '../../../../constant/Common/Path/Asset/Icon/Button';
import CommonTextInput from '../../../Common/Controller/Text';
import ToggleWithText from '../ToggleWithText';

const YMSYardContainer = ({
  ymsYard,
  div,
  postYMSYardMutation,
  patchYMSYardActivateMutation,
  deleteYMSYardMutation,
}) => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-[200px] h-full flex flex-col gap-y-[12px]">
        <div className="w-full h-[16px] font-medium text-[12px] text-[#595656] flex items-center">
          YARD
        </div>
        <div className="w-full min-h-[30px] flex mt-[-15px]">
          <CommonTextInput
            inputName="YMS Yard"
            placeholder="Add Yard"
            maxLength={10}
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value?.toUpperCase());
            }}
            onKeyDownHandler={({ e, setDisabled }) => {
              if (e.key === 'Enter') {
                setDisabled(true);
                e.preventDefault();
                postYMSYardMutation({
                  div,
                  name: e?.target?.value,
                  setDisabled,
                });
              }
            }}
          />
        </div>

        <div className="flex-1 w-full h-full p-[12px] flex flex-col items-center bg-white rounded-[5px] border border-zinc-300 overflow-x-hidden overflow-y-scroll">
          <div className="h-full w-full">
            {ymsYard?.map(item => (
              <div className="flex items-center justify-between">
                <div className="flex justify-between">
                  <ToggleWithText
                    key={item?.id}
                    name={item?.name}
                    inputName={item?.name}
                    isActive={item?.active}
                    mutation={patchYMSYardActivateMutation}
                    seq={item?.seq}
                    width="w-[150px]"
                  />
                </div>
                <div className="flex flex-col min-w-[16px] min-h-[16px]">
                  {!item?.active ? (
                    <button
                      type="button"
                      className="cursor-pointer"
                      onClick={() => {
                        deleteYMSYardMutation({ seq: item?.seq });
                      }}
                    >
                      <img
                        src={PATH_DELETE_RED_ONLY_ICON}
                        alt="Delete Red"
                        width={16}
                        height={16}
                      />
                    </button>
                  ) : (
                    <img
                      src={PATH_DELETE_GREY_ONLY_ICON}
                      alt="Delete Grey"
                      width={16}
                      height={16}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YMSYardContainer;
