import dayjs from 'dayjs';
import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constant/Common/DataGrid/cellType';
import { DAMAGE_STATUS } from '../../../../constant/Safety/DamageReport/standardCodes';
import { STYLE_COLUMN_ALIGNMENT } from '../../../../style/Safety/Common/style';
import datetimeComparator from '../../../Common/Datagrid/Comparator/datetimeComparator';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';
import { createOptionalSelectOption } from '../../../Common/Option/Generator/selectOptionCreator';
import { createBooleanData, createStatusData } from './dataSetterGenerator';
import generateDatetimeFilters from '../../../Common/Datagrid/Generator/generateDatetimeFilters';

const createDamageColumnDefs = ({
  completedPatchMutator,
  filterDivisionList,
  isEditable,
  disabledRef,
}) => {
  const columnDef = [
    {
      headerName: 'STATUS',
      width: 75,
      field: 'status',
      editable: false,
      valueGetter: gridParam => {
        const value = createStatusData(gridParam?.data?.status);
        return value;
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: createOptionalSelectOption({
          items: Object?.keys?.(DAMAGE_STATUS),
          isGridFilter: true,
        }),
      },
    },
    {
      headerName: 'DIV',
      width: 38,
      field: 'div',
      editable: false,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: filterDivisionList,
      },
    },
    { headerName: 'YARD', width: 70, field: 'yard', editable: false },
    {
      headerName: 'LOC',
      width: 72,
      field: 'yard_location',
      editable: false,
    },
    {
      headerName: 'CREATED BY',
      width: 94,
      field: 'created_by',
      editable: false,
    },
    {
      headerName: 'DMG DATE',
      width: 100,
      field: 'dmgDate',
      editable: false,
      cellClass: 'datetime',
      valueGetter: gridParam => {
        const stringReportDateTime = `${gridParam?.data?.report_date} ${gridParam?.data?.report_time}`;
        const value =
          stringReportDateTime && dayjs(stringReportDateTime)?.isValid()
            ? dayjs(stringReportDateTime)?.format('MM/DD/YY HH:mm')
            : '';
        return value;
      },
      comparator: datetimeComparator,
      ...generateDatetimeFilters(),
    },
    {
      headerName: 'DRIVER',
      width: 72,
      field: 'driver',
      editable: false,
    },
    { headerName: 'TRUCK #', width: 69, field: 'trk_no', editable: false },
    {
      headerName: 'PLATE #',
      width: 90,
      field: 'trk_plate_no',
      editable: false,
    },
    {
      headerName: 'TRUCK DMG',
      headerClass: 'trkDmgColor',
      backgroundColor: '#CCD3E3',
      isColumnGroup: true,
      children: [
        {
          headerName: 'BODY',
          width: 50,
          field: 'trk_dmg',
          editable: false,
          valueGetter: gridParam => {
            const value = createBooleanData(gridParam?.data?.trk_dmg);
            return value;
          },
          floatingFilterComponent: FloatingFilterSelect,
          floatingFilterComponentParams: {
            option: createOptionalSelectOption({
              items: ['Y', 'N'],
              isGridFilter: true,
            }),
          },
        },
        {
          headerName: 'TIRE',
          width: 50,
          field: 'trk_tire',
          editable: false,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              styles: {
                ...STYLE_COLUMN_ALIGNMENT.number,
              },
            }),
        },
      ],
    },
    {
      headerName: 'CONT #',
      width: 92,
      field: 'cont_no',
      editable: false,
    },
    {
      headerName: 'CHASSIS #',
      width: 86,
      field: 'ch_no',
      editable: false,
    },
    {
      headerName: 'CH PLATE #',
      width: 112,
      field: 'ch_plate_no',
      editable: false,
    },
    {
      headerName: 'CHAS DMG',
      headerClass: 'chasDmgColor',
      backgroundColor: '#909FC1',
      isColumnGroup: true,
      children: [
        {
          headerName: 'DMG',
          width: 50,
          field: 'ch_dmg',
          editable: false,
          valueGetter: gridParam => {
            const value = createBooleanData(gridParam?.data?.trk_dmg);
            return value;
          },
          floatingFilterComponent: FloatingFilterSelect,
          floatingFilterComponentParams: {
            option: createOptionalSelectOption({
              items: ['Y', 'N'],
              isGridFilter: true,
            }),
          },
        },
        {
          headerName: 'TIRE',
          width: 50,
          field: 'ch_tire',
          editable: false,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              styles: {
                ...STYLE_COLUMN_ALIGNMENT.number,
              },
            }),
        },
      ],
    },
    { headerName: 'O / R', width: 50, field: 'ch_tire_or', editable: false },
  ];

  if (isEditable) {
    columnDef?.push({
      headerName: 'COMPLETE',
      width: 100,
      field: 'completed',
      editable: false,
      clickable: true,
      valueGetter: params => {
        return params?.data?.completed ? 'Complete' : 'Incomplete';
      },
      cellRendererSelector: gridParam => {
        return generateCellRenderer({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          value: gridParam?.data?.completed,
          onChangeHandler: ({ ...props }) => {
            if (
              disabledRef?.current?.disabled &&
              disabledRef?.current?.disabledRow === gridParam?.rowIndex
            ) {
              return;
            }
            disabledRef.current.disabled = true;
            disabledRef.current.disabledRow = gridParam?.rowIndex;

            const { e, setChecked } = props;

            setChecked(!!e?.target?.checked);
            completedPatchMutator({ seq: gridParam?.data?.seq, setChecked });
          },
        });
      },
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: [
          {
            label: 'All',
            value: '',
          },
          {
            label: 'Complete',
            value: 'Complete',
          },
          {
            label: 'Incomplete',
            value: 'Incomplete',
          },
        ],
      },
    });
  }
  return columnDef;
};

export default createDamageColumnDefs;
