import React from 'react';
import CommonToggle from '../../../Common/Controller/Toggle/index';

const ToggleWithText = ({
  name,
  inputName,
  isBorder,
  isActive,
  div,
  mutation,
  seq,
  width,
}) => {
  const handleToggleChange = ({
    e,
    onChange,
    setDisabled,
    ...handlerProps
  }) => {
    onChange(e.target.checked);
    setDisabled(true);
    mutation(
      { div, type: inputName, seq, setDisabled },
      {
        onError: () => {
          onChange(!e.target.checked);
        },
      },
    );
  };

  return (
    <div
      className={`flex justify-between align-center h-[30px] 
      ${width || 'w-[160px]'} 
      ${isBorder ? 'rounded-[5px] border border-zinc-300 ' : ''}`}
    >
      <div className="text-black text-xs font-medium flex items-center w-[92px]">
        {name}
      </div>
      <div className="mt-[-15px]">
        <CommonToggle
          isBetween
          options={[
            {
              defaultValue: isActive,
              inputName: `${inputName}`,
            },
          ]}
          onChangeHandler={handleToggleChange}
        />
      </div>
    </div>
  );
};

export default ToggleWithText;
